<template>
  <div class="history-contract scroll-y" style="height: 700px;">
    <ul class="contract-list">
      <li v-for="(item, index) in resource" :key="index" class="contract-item">
        <article class="contract-card">
          <h3 class="contract-title">{{ item.position_name }}</h3>
          <div class="contract-details">
            <h4 class="section-title">{{ $t('employee.employment') }}</h4>
            <div class="details-grid">
              <div class="detail-item">
                <i class="icon icon-calendar"></i>
                <span class="detail-label">{{ $t('employee.contractStartDate') }}:</span>
                <strong class="detail-value">{{ item.contract_start_date }}</strong>
              </div>
              <div class="detail-item">
                <i class="icon icon-clock"></i>
                <span class="detail-label">{{ $t('employee.period') }}:</span>
                <strong class="detail-value">{{ item.contract_period }}</strong>
              </div>
              <div class="detail-item">
                <i class="icon icon-calendar"></i>
                <span class="detail-label">{{ $t('employee.expiredate') }}:</span>
                <strong class="detail-value">{{ item.contract_expire_date }}</strong>
              </div>
              <div class="detail-item">
                <i class="icon icon-clock"></i>
                <span class="detail-label">{{ $t('employee.resignOrTerminateTerm') }}:</span>
                <strong class="detail-value">{{ item.resign_or_terminate_term }}</strong>
              </div>
              <div class="detail-item">
                <i class="icon icon-briefcase"></i>
                <span class="detail-label">{{ $t('employee.positionName') }}:</span>
                <strong class="detail-value">{{ item.position_name }}</strong>
              </div>
              <div class="detail-item">
                <i class="icon icon-credit-card"></i>
                <span class="detail-label">{{ $t('employee.salaryLevel') }}:</span>
                <strong class="detail-value">{{  item.level_name + ' ('+ item.name + ')' }}</strong>
              </div>
              <div class="detail-item">
                <i class="icon icon-user"></i>
                <span class="detail-label">{{ $t('employee.marritalStatus') }}:</span>
                <strong class="detail-value">{{ item.marrital_status }}</strong>
              </div>
              <div class="detail-item">
                <i class="icon icon-shield"></i>
                <span class="detail-label">{{ $t('employee.guarantorName') }}:</span>
                <strong class="detail-value">{{ item.guarantor_name }}</strong>
              </div>
            </div>
          </div>
        </article>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'ViewHistoryContract',
  props: ['resource']
}
</script>

<style scoped>
.contract-list {
  list-style: none;
  padding: 0;
}

.contract-item {
  margin-bottom: 2rem;
}

.contract-card {
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
}

.contract-title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #333;
}

.section-title {
  background-color: #e9ecef;
  color: #495057;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  border-radius: 4px;
}

.details-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
}

.detail-item {
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 0.5rem;
  color: #6c757d;
}

.detail-label {
  font-size: 0.9rem;
  color: #6c757d;
  margin-right: 0.5rem;
}

.detail-value {
  font-size: 1rem;
  color: #212529;
}

.scroll-y{
	max-height: 700px;
  overflow-y: scroll;
}

@media (max-width: 768px) {
  .details-grid {
    grid-template-columns: 1fr;
  }
}
</style>