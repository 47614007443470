<template>
  <div>
    <Table :columns="columns" :data="resources" size="small" stripe>
      <template slot-scope="{ row }" slot="action">
        <a
          href="#"
          class="text-blue mr-2 ts-z-30"
          @click.prevent="onPreviewPdf(row)"
          v-tooltip="$t('employee.previewContract')"
        >
          <Icon type="ios-document" size="20" />
        </a>
        <a
          href="#"
          class="text-blue mr-2 ts-z-30"
          @click.prevent="onEdit(row)"
          v-tooltip="$t('edit')"
        >
          <Icon type="ios-create" size="20" />
        </a>
      </template>
    </Table>
    <Modal
            v-model="showEditContractInfo"
            draggable
            sticky
            scrollable
            :mask="true"
            :footer-hide="true"
            :z-index="1022"
            :title="employee_name"
            width="900px"
        >
            <edit-contract-info
                ref="edit_contract_info"
                @fetchData="fetchData"
                :contract_id="contract_id"
                @cancel="close"
            />
    </Modal>
    <pdf-preview v-model="pdf_view" :contract_id="contract_id" @cancel="close"/>
  </div>
</template>
<script>
import { orderBy } from 'lodash'
import PdfPreview from './pdf-preview.vue'
import { mapState } from "vuex";
import editContractInfo from "./edit-contract-info";

export default {
  props: {
    row: Object
  },
  components: {
    PdfPreview,
    editContractInfo
  },
  data () {
    return {
      loading: false,
      pdf_view: false,
      contract_id: null,
      employee_name: null,
      showEditContractInfo: false,
    }
  },
  computed: {
    ...mapState("humanResource/employee", ["pagination"]),
    resources () {
      return orderBy(this.row.contracts)
    },
    columns () {
      return [
        {
          title: this.$t('actions'),
          key: 'action',
          slot: 'action',
          align: 'center',
          width: 150
        },
        {
          title: this.$t('employee.positionName'),
          key: 'position_name',
          sortable: true,
          width: 150
        },
        {
          title: this.$t('employee.contractStartDate'),
          key: 'contract_start_date',
          sortable: true,
          width: 200
        },
        {
          title: this.$t('employee.period'),
          key: 'contract_period',
          align: 'center',
          sortable: true
        },
        {
          title: this.$t('employee.expiredate'),
          key: 'contract_expire_date',
          align: 'center',
          sortable: true
        },
        {
          title: this.$t('employee.guarantorName'),
          key: 'guarantor_name',
          align: 'center',
          sortable: true
        },
        {
          title: this.$t('employee.spouseName'),
          key: 'spouse_name',
          align: 'center',
          sortable: true
        },
        {
          title: this.$t('employee.relationship'),
          key: 'relationship',
          align: 'center',
          sortable: true
        }
      ]
    }
  },
  methods: {
    fetchData (attributes) {
      this.loading = true
      this.$store
        .dispatch('humanResource/employee/fetch',
            Object.assign({ page: this.pagination.currentPage }, attributes)
        )
        .catch(error => {
          this.loading = false
          this.notice({ type: 'error', text: error.message })
        })
        .finally(() => {
          this.loading = false
        })
    },
    onPreviewPdf (record) {
      this.pdf_view = true
      this.contract_id = record.contract_id
    },
    close() {
      this.pdf_view = false
      this.showEditContractInfo = false
      this.contract_id = null
    },
    onEdit(record) {
      this.showEditContractInfo = true;
      this.employee_name =
          this.row.employee_name_en + " | " + this.row.employee_name_kh;
      this.$store.commit("humanResource/employee/SET_EDIT_DATA", record);
      this.$refs.edit_contract_info.fetchResource();
      this.$refs.edit_contract_info.setEditData();
    },
  }
}
</script>
